<template>
    <div class="input-time d-inline-flex flex-row flex-nowrap justify-start align-center">
    	<input-counter 
            v-model.number="hour" 
            vertical 
            :min="0"
            :max="23"
            :step="hourStep"
            :disabled-min="disabled"
            :disabled-max="disabled"
            lead-zero
            :button-size="16"
        />
        <span>:</span>
        <input-counter 
            v-model.number="minute" 
            vertical 
            :min="0"
            :max="59"
            :step="minuteStep"
            :disabled-min="disabled"
            :disabled-max="disabled"
            lead-zero
            :button-size="16"
        />
        <span v-if="showSeconds">:</span>
        <input-counter 
            v-if="showSeconds"
            v-model.number="seconds" 
            vertical 
            :min="0"
            :max="59"
            :step="secondStep"
            :disabled-min="disabled"
            :disabled-max="disabled"
            lead-zero
            :button-size="16"
        />
    </div>
</template>
<script>
import TimeHelper from '@/helpers/TimeHelper';
import InputCounter from './InputCounter.vue'
export default {
    name: 'InputTime',
    components: { InputCounter },
    model: {
    	prop: 'value',
    	event: 'change'
    },
    props: {
    	value: Number,
        showSeconds: Boolean,
        disabled: Boolean,
        hourStep: {
            type: Number,
            default: 1
        },
        minuteStep: {
            type: Number,
            default: 5
        },
        secondStep: {
            type: Number,
            default: 5
        }
    },
    data() {
    	return {
    		hour: 0,
            minute: 0,
            seconds: 0
    	}
    },
    computed: {
        valueTime() {
            return TimeHelper.secondsToTime(this.value, true);
        },
        totalSeconds() {
            return _.sum([this.hour * 60 * 60, this.minute * 60, this.seconds]);
        }
    },
    created() {
        _.assign(this, this.valueTime);
    },
    watch: {
        valueTime(val) {
            _.assign(this, val);
        },
        totalSeconds(val) {
            this.$emit('change', val);
        }
    }
}
</script>