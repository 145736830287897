<template>
    <div class="input-counter d-inline-flex flex-nowrap justify-start align-center" :class="[this.vertical === true ? 'input-counter--vertical flex-column' : 'flex-row']">
    	<v-btn icon :disabled="disabledMin || isMin" :width="buttonSize" :height="buttonSize" @click.prevent.stop="change(-1)">
    		<v-icon>{{ iconSub }}</v-icon>
    	</v-btn>
    	<span 
            class="fs-16 lh-19 mx-3 primary--text" 
            :class="{'text--disabled': isMin}"
        >
            {{ formattedCounter }}
        </span>
    	<v-btn icon :disabled="disabledMax || isMax" :width="buttonSize" :height="buttonSize" @click.prevent.stop="change(1)">
    		<v-icon>{{ iconAdd }}</v-icon>
    	</v-btn>
    </div>
</template>
<script>
export default {
    name: 'InputCounter',
    model: {
    	prop: 'value',
    	event: 'change'
    },
    props: {
    	value: Number,
    	min: Number,
    	max: Number,
        step: {
            type: Number,
            default: 1
        },
        leadZero: Boolean,
        vertical: Boolean,
        buttonSize: Number,
        disabledMin: Boolean,
        disabledMax: Boolean
    },
    data() {
    	return {
    		counter: this.value
    	}
    },
    computed: {
        prevCounterValue() {
            return this.counter - this.step;
        },
        nextCounterValue() {
            return this.counter + this.step;
        },
    	isMin() {
    		return _.isNil(this.min) ? false : (this.counter <= this.min || this.prevCounterValue < this.min);
    	},
    	isMax() {
    		return _.isNil(this.max) ? false : (this.counter >= this.max || this.nextCounterValue > this.max);
    	},
        iconAdd() {
            return `mdi-${this.vertical ? 'chevron-up' : 'plus'}`;
        },
        iconSub() {
            return `mdi-${this.vertical ? 'chevron-down' : 'minus'}`;
        },
        formattedCounter() {
            const hasLeadZero = (this.leadZero === true && this.counter >= 0 && this.counter <= 9);
            return `${hasLeadZero ? '0' : ''}${this.counter}`;
        }
    },
    methods: {
    	change(val) {
    		if((this.isMin && val < 0) || (this.isMax && val > 0))
    			return ;

    		this.counter += val * this.step;
    		this.$emit('change', this.counter);
    	}
    }
}
</script>
<style lang="scss">
    .input-counter{
        &.input-counter--vertical{
            > .v-btn{
                &:nth-child(1) {
                    order: 3;
                }

                &:nth-child(2) {
                    order: 1;
                }
            }

            > span {
                order: 2;
            }
        }
    }
</style>