<template>
    <v-date-picker
        v-model="date"
        v-bind="datePickerProps"
    >
        <div class="d-flex flex-column justify-stretch align-stretch" style="width:100%;">
            <div v-if="isDateSelected">
                <v-divider class="my-3" />

                <div class="d-flex flex-row flex-nowrap justify-center align-center">
                    <input-time v-model.number="time" />
                </div>
            </div>

            <v-divider class="my-3" />

            <slot />
        </div>
    </v-date-picker>
</template>
<script>
import TimeHelper from '@/helpers/TimeHelper';
import InputTime from '@/components/inputs/InputTime.vue';

export default {
    name: 'VDateTimePicker',
    components: { InputTime },
    props: {
        value: String,
        datePickerProps: Object
    },
    data() {
    	return {
    		date: null,
            time: null
    	}
    },
    computed: {
        isDateSelected() {
            return !_.isNil(this.date) && _.size(this.date) > 0;
        },
        valueDate() {
            return _.get(_.split(this.value, ' '), 0, '');
        },
        valueTime() {
            return _.get(_.split(this.value, ' '), 1, '12:00:00');
        },
        valueTimeSeconds() {
            return this.isDateSelected ? TimeHelper.timeToSeconds(this.valueTime) : null;
        },
        dateTime() {
            const time = TimeHelper.secondsToTime(this.time);
            return _.join([this.date, time.substring(0, time.length - 3)], ' ');
        }
    },
    created() {
        this.date = this.valueDate;
        this.time = this.valueTimeSeconds;
    },
    beforeDestroy() {
        if(_.isNil(this.date))
            this.time = null;
    },
    watch: {
        valueDate(val) {
            this.date = val;
        },
        valueTimeSeconds(val) {
            this.time = val;
        },
        dateTime(val) {
            this.$emit('input', val);
        }
    }
}
</script>